import styled from 'styled-components';

import { media } from 'helpers/breakpoints';

import {
  LabelProps,
  SpacingProps,
  FontSizeProps,
} from 'components/ToolkitV2/CardV2/components/Price/Price.typed';

const Container = styled.div`
  display: flex;
  align-items: end;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceLabelWrapper = styled.div<{ flip?: boolean }>`
  position: relative;
  ${({ theme }) => theme.fontSize.M14};

  ${media.medium} {
    order: ${({ flip }) => (flip ? '2' : '0')};
  }
`;

const PriceDrop = styled.span<FontSizeProps>`
  ${({ theme, smallFontSize }) => theme.fontSize[smallFontSize]};
  color: ${({ theme }) => theme.colors.RED};
  position: relative;

  ${media.medium} {
    ${({ theme, mediumFontSize }) => theme.fontSize[mediumFontSize]};
  }

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    transform: rotate(-12.2deg);
  }
`;

const Label = styled.p<LabelProps>`
  display: ${({ isGrid }) => (isGrid ? 'none' : 'inline-block')};
  ${({ theme }) => theme.fontSize.M14};

  ${media.medium} {
    display: inline-block;
  }
`;

const Text = styled.p<FontSizeProps>`
  ${({ theme, smallFontSize }) => theme.fontSize[smallFontSize]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.GREY_DARKER};

  ${media.medium} {
    ${({ theme, mediumFontSize }) => theme.fontSize[mediumFontSize]};
  }
`;

const NextToPriceContainer = styled.div<SpacingProps>`
  border-left: solid ${({ theme }) => theme.colors.GREY_LIGHT} 1px;
  padding-left: ${({ theme, gapSmall }) => theme.spacing[gapSmall]};
  margin-left: ${({ theme, gapSmall }) => theme.spacing[gapSmall]};

  ${media.medium} {
    padding-left: ${({ theme, gapMedium }) => theme.spacing[gapMedium]};
    margin-left: ${({ theme, gapMedium }) => theme.spacing[gapMedium]};
  }
`;

const PricePerMonthDisplay = styled.span`
  ${({ theme }) => theme.fontSize.B12};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export {
  Container,
  NextToPriceContainer,
  PriceLabelWrapper,
  PriceDrop,
  Label,
  Text,
  PricePerMonthDisplay,
  PriceContainer,
};
