import styled from 'styled-components';

import { media } from 'helpers/breakpoints';

import type {
  TitleProps,
  KeyInfoItemProps,
} from 'components/ToolkitV2/CardV2/components/BasicHeader/BasicHeader.typed';

const Title = styled.p<TitleProps>`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  ${({ theme, titleSmallFontSize }) => theme.fontSize[titleSmallFontSize]};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  overflow: hidden;
  word-break: break-word;

  @supports (-webkit-line-clamp: 2) {
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  ${media.medium} {
    ${({ theme, titleMediumFontSize }) => theme.fontSize[titleMediumFontSize]};
  }
`;

const KeyInfoList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const KeyInfoItem = styled.li<KeyInfoItemProps>`
  ${({ theme, keyInfoItemSmallFontSize }) =>
    theme.fontSize[keyInfoItemSmallFontSize]};
  list-style: none;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.GREY_DARK};

  &:after {
    width: 4px;
    height: 4px;
    background: ${({ theme }) => theme.colors.GREY_DARK};
    margin-left: ${({ theme }) => theme.spacing.S8};
    margin-right: ${({ theme }) => theme.spacing.S8};
    content: '';
    border-radius: 50%;
  }

  &:last-child {
    &:after {
      width: ${({ theme }) => theme.spacing.S4};
      content: none;
      height: 100%;
    }
  }

  ${media.medium} {
    ${({ theme }) => theme.fontSize.M14}
  }
`;

export { Title, KeyInfoList, KeyInfoItem };
