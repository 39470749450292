import styled from 'styled-components';

import { ORIENTATION } from 'components/ToolkitV2/CardV2/Card.typed';
import type { OrientationProps } from 'components/ToolkitV2/CardV2/Card.typed';
import { media } from 'helpers/breakpoints';

interface PictureWrapperProps extends OrientationProps {
  widthHorizontalSmall?: number;
  widthHorizontalMedium?: number;
  widthHorizontalLarge?: number;
}
const PictureWrapper = styled.div<PictureWrapperProps>`
  width: ${({ orientationSmall, widthHorizontalSmall }) =>
    orientationSmall === ORIENTATION.HORIZONTAL && widthHorizontalSmall
      ? `${widthHorizontalSmall}px`
      : orientationSmall === ORIENTATION.VERTICAL
      ? '100%'
      : undefined};

  ${media.medium} {
    width: ${({ orientationMedium, widthHorizontalMedium }) =>
      orientationMedium === ORIENTATION.HORIZONTAL && widthHorizontalMedium
        ? `${widthHorizontalMedium}px`
        : orientationMedium === ORIENTATION.VERTICAL
        ? '100%'
        : undefined};
  }

  ${media.large} {
    width: ${({ orientationLarge, widthHorizontalLarge }) =>
      orientationLarge === ORIENTATION.HORIZONTAL && widthHorizontalLarge
        ? `${widthHorizontalLarge}px`
        : orientationLarge === ORIENTATION.VERTICAL
        ? '100%'
        : undefined};
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;
`;

export { PictureWrapper, Img };
