import * as Styled from 'components/ToolkitV2/CardV2/components/Price/Price.styled';

import { PriceTypes } from 'components/AdForm/types';
import { CURRENCIES } from 'types';
import type { PriceProps } from 'components/ToolkitV2/CardV2/components/Price/Price.typed';

const Price = (props: PriceProps) => {
  const {
    price,
    oldPrice,
    currency,
    pricePerMonth,
    priceOnRequest,
    flip,
    isGrid,
    primaryLabel = 'Price',
    secondaryLabel = 'From',
    displayLabels = true,
    priceSmallFontSize = 'L18',
    priceMediumFontSize = 'L18',
    gapSmall = 'S8',
    gapMedium = 'M16',
    priceDropSmallFontSize = 'B10',
    priceDropMediumFontSize = 'M14',
  } = props;

  const currencyDisplay = currency === CURRENCIES.GBP ? '£' : '€';

  const priceDisplay = price ? `${currencyDisplay}${price}` : 'No Price';

  const pricePerMonthDisplay = pricePerMonth
    ? `${currencyDisplay}${pricePerMonth}`
    : 'n/a';

  const oldPriceDisplay = oldPrice ? `${currencyDisplay}${oldPrice}` : 'n/a';

  return (
    <Styled.Container>
      <Styled.PriceContainer>
        <Styled.PriceLabelWrapper flip={flip}>
          {displayLabels && (
            <Styled.Label isGrid={isGrid}>
              <span>{primaryLabel}&nbsp;</span>
            </Styled.Label>
          )}
          {oldPrice && (
            <Styled.PriceDrop
              smallFontSize={priceDropSmallFontSize}
              mediumFontSize={priceDropMediumFontSize}
            >
              {oldPriceDisplay}
            </Styled.PriceDrop>
          )}
        </Styled.PriceLabelWrapper>
        <Styled.Text
          smallFontSize={priceSmallFontSize}
          mediumFontSize={priceMediumFontSize}
        >
          {!priceOnRequest ? priceDisplay : PriceTypes.ON_REQUEST}
        </Styled.Text>
      </Styled.PriceContainer>
      {pricePerMonth && (
        <Styled.NextToPriceContainer gapSmall={gapSmall} gapMedium={gapMedium}>
          <Styled.PriceLabelWrapper>
            {displayLabels && (
              <Styled.Label isGrid={isGrid}>{secondaryLabel}</Styled.Label>
            )}
          </Styled.PriceLabelWrapper>
          <Styled.Text
            smallFontSize={priceSmallFontSize}
            mediumFontSize={priceMediumFontSize}
          >
            {pricePerMonthDisplay}&nbsp;
            <Styled.PricePerMonthDisplay>p/m</Styled.PricePerMonthDisplay>
          </Styled.Text>
        </Styled.NextToPriceContainer>
      )}
    </Styled.Container>
  );
};

export { Price };
