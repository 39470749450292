import * as Styled from 'components/ToolkitV2/CardV2/components/BasicHeader/BasicHeader.styled';

import type { BasicHeaderProps } from 'components/ToolkitV2/CardV2/components/BasicHeader/BasicHeader.typed';

function BasicHeader(props: BasicHeaderProps) {
  const {
    title,
    metaData,
    titleSmallFontSize = 'L18',
    titleMediumFontSize = 'L18',
    keyInfoItemSmallFontSize = 'M14',
  } = props;

  return (
    <div>
      <Styled.Title
        titleSmallFontSize={titleSmallFontSize}
        titleMediumFontSize={titleMediumFontSize}
      >
        {title}
      </Styled.Title>
      {metaData && (
        <Styled.KeyInfoList>
          {metaData.map(
            (item: string, index: number) =>
              item && (
                <Styled.KeyInfoItem
                  key={`card-key-info-item-${index}`}
                  keyInfoItemSmallFontSize={keyInfoItemSmallFontSize}
                >
                  {item}
                </Styled.KeyInfoItem>
              ),
          )}
        </Styled.KeyInfoList>
      )}
    </div>
  );
}

export { BasicHeader };
