import * as Styled from 'components/ToolkitV2/CardV2/components/Image/Image.styled';

import { CDN_STATIC_ASSETS } from 'utils';

import type { ImageProps } from 'components/ToolkitV2/CardV2/components/Image/Image.typed';

const FallbackImage = `${CDN_STATIC_ASSETS}/images/fallbacks/card-no-image.svg`;

/*
Note that for every width supplied there must be a corresponding orientation
*/

function Image(props: ImageProps) {
  const {
    src,
    alt,
    heightVertical,
    widthVertical,
    widthHorizontalSmall,
    widthHorizontalMedium,
    widthHorizontalLarge,
    orientationSmall,
    orientationMedium,
    orientationLarge,
    imgSrcWebp,
  } = props;

  return (
    <Styled.PictureWrapper
      orientationSmall={orientationSmall}
      orientationMedium={orientationMedium}
      orientationLarge={orientationLarge}
      widthHorizontalSmall={widthHorizontalSmall}
      widthHorizontalMedium={widthHorizontalMedium}
      widthHorizontalLarge={widthHorizontalLarge}
    >
      <picture>
        {imgSrcWebp && <source type="image/webp" srcSet={imgSrcWebp} />}
        <Styled.Img
          src={src ?? FallbackImage}
          alt={alt ?? 'no-image'}
          /* Add explicit height and width for vertical orientation of card
            to preserve space in DOM while image loads in order to prevent shift
            as image loads, otherwise use the "gap..."" props on card for horizontal 
            orientations */
          height={heightVertical}
          width={widthVertical}
        />
      </picture>
    </Styled.PictureWrapper>
  );
}

export { Image };
